import React from "react";

const UpToWhat = () => {
  return (
    <div className="contentDescription">
      <section className="current-focus">
        <h2>📱 Building & Experimenting</h2>
        <br />
        <div className="infoDescription">
          <p>
            Lately, I’ve been focused on developing{" "}
            <strong>personal open-source mobile app projects</strong>, refining
            my skills in <strong>React Native</strong> while exploring new tools
            and technologies. I frequently work with <strong>Expo</strong>,
            leveraging its capabilities to streamline development while also
            testing out <strong>new libraries</strong> to push the boundaries of
            what’s possible.
          </p>

          <br />
          <p>
            My focus isn’t just on coding, I’m exploring different technical
            aspects, refining UI/UX, and evaluating how modern tools can shape
            scalable and performant applications. Every day brings a new
            challenge, and that’s what keeps it exciting.
          </p>
        </div>
      </section>
      <br />
      <section className="career-goals">
        <h2>🚀 The Next Chapter: Future Goals</h2>
        <br />
        <div className="infoDescription">
          <p>
            While open source is my current playground, my long-term aspirations
            remain clear. I see myself diving deeper into high-impact mobile
            projects, leading teams, and crafting solutions that change the way
            people interact with technology.
          </p>
          <br />
          <p>
            I'm especially interested in technical leadership roles, where I can
            mentor and architect scalable solutions.
          </p>
          <br />
          <p>
            Remote work? Definitely an option, especially for projects that
            challenge me and push my boundaries.
          </p>
          <br />
          <p>
            For now, I'm enjoying the journey; contributing, learning, and
            preparing for what's next.
          </p>
        </div>
      </section>
    </div>
  );
};

export default UpToWhat;
