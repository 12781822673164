import SocialIcons from "../../components/SocialIcons";
import React from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/about");
  };

  return (
    <div className="full-screen">
      <div className="landing">
        <div className="textContainer">
          <span className="level-prefix">Senior-Adjacent</span>
          <span className="role-title">React Native Engineer</span>
          <div className="bottom-container">
            <div className="bottom-button-container">
              <button
                className="text-button about-me-btn"
                onClick={handleClick}
                aria-label="Learn more about me"
              >
                About Me
                <i className="fas fa-arrow-right" aria-hidden="true"></i>
              </button>
              <button
                className="text-button about-me-btn"
                onClick={() => navigate("/now")}
                aria-label="Learn more about me"
              >
                🌱 What I’m Growing Into
                <i className="fas fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
            <SocialIcons />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
