import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import projectData from "./projectsData.json";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";

const Portfolio = () => {
  const navigate = useNavigate();

  // Use useCallback to avoid recreating handleClick on every render
  const handleClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="full-screen">
      <div className="portfolio">
        <PageHeader title="Projects" handleClick={handleClick} />
        <div className="row">
          {projectData.map((project) => (
            <Project key={project.id} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
