// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import Modal from "react-modal";
import {
  faTimes,
  faInfoCircle,
  faCode,
  faTools,
  faLink,
  faImages,
} from "@fortawesome/free-solid-svg-icons";
// Separate styles into a dedicated object
const styles = {
  sectionIcon: {
    marginRight: 10,
  },
  screenshots: {
    display: "flex",
    gap: "15px",
    overflowX: "auto",
    padding: "10px 0",
    marginBottom: "15px",
  },
  screenshot: {
    minWidth: "200px",
    height: "400px",
    borderRadius: "12px",
    objectFit: "cover",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    cursor: "pointer",
    transition: "transform 0.3s ease",
  },
  modalStyles: {
    base: {
      backgroundColor: "#101010",
      color: "#9f9f9f",
      padding: "2rem",
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      zIndex: 99,
      borderRadius: "20px",
      width: "65%",
      maxHeight: "80vh",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    mobile: {
      width: "90%",
      padding: "1.5rem",
    },
  },
  text: {
    italic: {
      fontStyle: "italic",
      fontSize: "12px",
      color: "#7f7f7f",
    },
    italicWhite: {
      fontStyle: "italic",
      fontSize: "12px",
      color: "#fff",
    },
  },
  modal: {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 30,
    },
    logo: {
      cursor: "pointer",
      width: "50px",
      height: "50px",
      marginRight: "10px",
      border: "1px solid black",
      borderRadius: "10px",
      backgroundColor: "white",
    },
    closeButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      cursor: "pointer",
      backgroundColor: "white",
      borderRadius: "50%",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    },
    closeIcon: {
      width: "24px",
      height: "24px",
      color: "black",
    },
  },
  projectCard: {
    base: {
      height: "175px",
      padding: "20px",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
      position: "relative",
      overflow: "hidden",
      border: "1px solid transparent",
    },
    hover: {
      transform: "translateY(-5px)",
      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
    },
  },
  cardContent: {
    position: "relative",
    zIndex: 2,
  },
  techBadge: {
    display: "inline-block",
    padding: "4px 8px",
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginRight: "8px",
    marginBottom: "8px",
    fontSize: "12px",
    color: "#fff",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(45deg, rgba(0,0,0,0.1), rgba(0,0,0,0))",
    zIndex: 1,
  },
  modalContent: {
    overflow: "auto",
    padding: "1rem",
    flex: 1,
  },
  sectionTitle: {
    color: "#fff",
    fontSize: "1.2rem",
    marginBottom: "1rem",
    marginTop: "1.5rem",
  },
  contributionItem: {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    padding: "12px",
    borderRadius: "8px",
    marginBottom: "8px",
  },
};

// Separate LinkList into its own component
const LinkList = ({ links }) => {
  const isValidUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "\n]+$/;
    return urlRegex.test(url);
  };

  return links.map((item, index) => (
    <div key={index} style={{ marginBottom: 10 }}>
      <p className="tech text-center">
        <em style={styles.text.italic}>{item.title}:</em>
      </p>
      <p className="tech text-center">
        {isValidUrl(item.link) ? (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <em>{item.link}</em>
          </a>
        ) : (
          <em>{item.link}</em>
        )}
      </p>
      <p className="tech text-center">
        <em style={styles.text.italic}>{item.description}</em>
      </p>
    </div>
  ));
};

// Separate ProjectCard into its own component
const ProjectCard = ({ project, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle = {
    ...styles.projectCard.base,
    backgroundColor: project.bgcolor,
    ...(isHovered && styles.projectCard.hover),
  };

  return (
    <div
      style={cardStyle}
      className="projectCard d-flex flex-column align-items-start justify-content-center"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h3 className="companyTitle">{project.company?.companyName}</h3>
      <div
        className="project-header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h3 className="projectTitle text-center">{project.title}</h3>
        <h2 style={{ fontSize: "0.65rem", fontWeight: "normal" }}>
          ({project.category})
        </h2>
      </div>
      <div className="tech-stack">
        {project.technologies
          .split(",")
          .slice(0, 6)
          .map((tech, index) => (
            <span key={index} style={styles.techBadge}>
              {tech.trim()}
            </span>
          ))}
      </div>
    </div>
  );
};

const Project = ({ project }) => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const [showModal, setShowModal] = useState(false);
  const isMobile = window.innerWidth <= 768;

  Modal.setAppElement("#root");

  const modalStyles = {
    ...styles.modalStyles.base,
    ...(isMobile && styles.modalStyles.mobile),
  };

  return (
    <div ref={ref} className="col-sm-12 col-lg-6">
      <ProjectCard project={project} onClick={() => setShowModal(true)} />

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: styles.modal.overlay,
          content: modalStyles,
        }}
      >
        <div style={styles.modal.header}>
          <img
            src={project.projectLogo}
            style={styles.modal.logo}
            alt={project.title}
          />
          <h3 className="modalTitle" style={{ fontSize: "1.5rem" }}>
            {project.title}
          </h3>
          <div style={{ flex: 1 }} />
          <div
            onClick={() => setShowModal(false)}
            style={styles.modal.closeButton}
          >
            <FontAwesomeIcon icon={faTimes} style={styles.modal.closeIcon} />
          </div>
        </div>

        <div style={styles.modalContent}>
          <section>
            <h4 style={styles.sectionTitle}>
              <FontAwesomeIcon icon={faInfoCircle} style={styles.sectionIcon} />
              Project Description
            </h4>
            <p className="projectDescription">{project.description}</p>
          </section>

          {project.screenshots && project.screenshots.length > 0 && (
            <section>
              <h4 style={styles.sectionTitle}>
                <FontAwesomeIcon icon={faImages} style={styles.sectionIcon} />
                Screenshots
              </h4>
              <div style={styles.screenshots}>
                {project.screenshots.map((screenshot, index) => (
                  <img
                    key={index}
                    src={screenshot}
                    alt={`${project.title} screenshot ${index + 1}`}
                    style={styles.screenshot}
                    onClick={() => window.open(screenshot, "_blank")}
                  />
                ))}
              </div>
            </section>
          )}
          
          <section>
            <h4 style={styles.sectionTitle}>
              <FontAwesomeIcon icon={faCode} style={styles.sectionIcon} />
              My Contributions
            </h4>
            <p className="projectDescription">{project.contributions}</p>
          </section>

          <section>
            <h4 style={styles.sectionTitle}>
              <FontAwesomeIcon icon={faTools} style={styles.sectionIcon} />
              Technologies
            </h4>
            <div className="tech-stack mb-4">
              {project.technologies.split(",").map((tech, index) => (
                <span key={index} style={styles.techBadge}>
                  {tech.trim()}
                </span>
              ))}
            </div>
          </section>

          <section>
            <h4 style={styles.sectionTitle}>
              <FontAwesomeIcon icon={faLink} style={styles.sectionIcon} />
              Links
            </h4>
            <LinkList links={project.links} />
          </section>
        </div>
      </Modal>
    </div>
  );
};

export default Project;
