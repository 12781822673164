import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import AboutMe from "../../components/AboutMe";
import "./about.css";

const About = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector(".about").classList.add("fade-in");
  }, []);

  const handleClick = () => {
    navigate(-1); // Removed the fade-out animation
  };

  return (
    <div className="full-screen">
      <div className="about">
        <PageHeader title="About Me 👨‍💻" handleClick={handleClick} />
        <AboutMe />
      </div>
    </div>
  );
};

export default About;
